@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
$fa-font-path: "/assets/webfonts";
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/brands";
@import "@fortawesome/fontawesome-pro/scss/light";
@import "@fortawesome/fontawesome-pro/scss/solid";
